import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import styled from "styled-components";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { CCard, CCardHeader } from "@coreui/react";
import { TextField, FormControl, InputLabel, CircularProgress } from "@mui/material";
import Select from 'react-select';

import CustomeLoader from "../../components/CustomeLoader";

const Billing = () => {
    const [billingData, setBillingData] = useState([]);
    const [pending, setPending] = useState(true);
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const [customerName, setCustomerName] = useState("");
    const [amount, setAmount] = useState("");

    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(true);

    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });

    const navigate = useNavigate();
    useEffect(() => {
        if (!localStorage.getItem("status")) {
            navigate("/");
        }
    }, [navigate]);
    const fetchBillingData = () => {
        axios
            .get("/api/all_customers_wallet")
            .then((response) => {
                console.log(response.data.data.data);
                setBillingData(response.data.data.data); // Update billing data
            })
            .catch((err) => {
                console.error(err);
                setBillingData([]); // In case of error, clear the billing data
            });
    };

    useEffect(() => {
        axios
            .get("/api/get_all_customer")
            .then((res) => {
                if (res.data.status === "Success") {
                    setCustomers(res.data.data);
                }
            })
            .finally(() => setLoading(false));

        fetchBillingData();

    }, []);
    const customerOptions = customers.map((customer) => ({
        value: customer.id,
        label: customer.name,
    }));

    useEffect(() => {
        const timeout = setTimeout(() => {
            setPending(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, []);

    const columns = [
        {
            name: "#",
            selector: (row, index) => <div>{index + 1}</div>,
            width: "150px",
        },
        {
            name: "Customer Name",
            selector: (row) => row.customer?.name || "Not available",
            width: "250px",
        },
        {
            name: "Amount",
            selector: (row) => row.amount || "Not available",
            width: "150px",
        },
        {
            name: "Type",
            selector: (row) => row.type || "Not available",
            width: "200px",
        },
    ];


    const handleSubmit = () => {
        if (!customerName || !amount) {
            Toast.fire({
                icon: "warning",
                title: "Please fill in both fields!",
            });
            return;
        }

        const data = new FormData();
        data.append("user_id", customerName);
        data.append("balance", amount);

        axios
            .post("/api/add_balance_wallet", data)
            .then((res) => {
                if (res.data && res.data.status === "Success") {
                    Toast.fire({
                        icon: "success",
                        title: "Balance added successfully!",
                    });

                    // Call fetchBillingData after adding the balance
                    fetchBillingData();

                    setCustomerName("");
                    setAmount("");
                } else {
                    Toast.fire({
                        icon: "error",
                        title: "Something went wrong with the response!",
                    });
                }
            })
            .catch((err) => {
                console.error(err);
                Toast.fire({
                    icon: "error",
                    title: "Error in submitting data!",
                });
            });
    };



    const filteredItems = billingData.filter(
        (item) =>
            (item.customer_name && item.customer_name.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.amount && item.amount.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.status && item.status.toLowerCase().includes(filterText.toLowerCase()))
    );

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-2 p-0">
                        <Sidebar />
                    </div>
                    <div className="col-sm-10">
                        <div className="container-fluid">
                            <Navbar />
                            <CCard className="mb-4 ml-2 mt-2">
                                <CCardHeader>
                                    <strong>Billing</strong>
                                </CCardHeader>
                                <div className="customerContainer">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-sm-4 mt-2">
                                                <label htmlFor="customerName" className="form-label">Customer Name</label>
                                                <FormControl fullWidth variant="outlined">
                                                    <Select
                                                        value={customerOptions.find(option => option.value === customerName) || null}
                                                        onChange={(selectedOption) => setCustomerName(selectedOption?.value || "")}
                                                        options={customerOptions}
                                                        isLoading={loading}
                                                        placeholder="Select Customer"
                                                        noOptionsMessage={() => "No customers found"}
                                                        styles={{
                                                            menuPortal: (base) => ({
                                                                ...base,
                                                                zIndex: 9999,
                                                            }),
                                                            control: (base) => ({
                                                                ...base,
                                                                zIndex: 1050,
                                                                height: 55,
                                                                minHeight: 55,
                                                            }),
                                                            dropdownIndicator: (base) => ({
                                                                ...base,
                                                                padding: 4,
                                                            }),
                                                        }}
                                                        menuPortalTarget={document.body}
                                                    />
                                                </FormControl>


                                            </div>
                                            <div className="col-sm-4 mt-2">
                                                <label htmlFor="amount" className="form-label">Amount</label>

                                                <TextField
                                                    id="amount"
                                                    variant="outlined"
                                                    placeholder="Enter Amount"
                                                    className="input-field w-100 mb-3"
                                                    name="amount"
                                                    value={amount}
                                                    onChange={(e) => setAmount(e.target.value)}
                                                    onInput={(e) => {
                                                        e.target.value = e.target.value.replace(/[^0-9+\-*/.,]/g, '')
                                                    }}
                                                />
                                            </div>
                                            <div className="col-sm-4 mt-2 text-right">
                                                {/* Submit Button */}
                                                <button
                                                    className="btn btn-success mt-4"
                                                    onClick={handleSubmit}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>

                                        {/* DataTable */}
                                        <div className="m-1 mt-4">
                                            <DataTable
                                                columns={columns}
                                                data={filteredItems}
                                                defaultSortFieldId
                                                fixedHeaderScrollHeight="480px"
                                                fixedHeader
                                                responsive
                                                pagination={10}
                                                subHeaderAlign="right"
                                                highlightOnHover
                                                progressPending={pending}
                                                progressComponent={<CustomeLoader />}
                                                noDataComponent={<div style={{ padding: '10px' }}>No billing records found</div>}

                                            />
                                        </div>
                                    </div>
                                </div>
                            </CCard>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Billing;
