import React, { useEffect, useState } from "react";
import axios from "axios";
import { AccordionDetails, Typography, TextField } from "@mui/material";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate } from "react-router-dom";
import { CCardHeader } from "@coreui/react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const EditSetting = () => {
  const [helpCenter, setHelpCenter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [image, setImage] = useState("");

  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [address, setAddress] = useState("");
  const [website, setWebsite] = useState("");
  const [minimum_order_value, setMinimumOrder] = useState("");


  const [accountHolderName, setAccountHolderName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [bankName, setBankName] = useState("");
  const [cashback, setCashback] = useState("");
  const [returnDays, setReturnDays] = useState("");

  const navigate = useNavigate();

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  useEffect(() => {
    axios
      .get("/api/get_settings")
      .then((res) => {
        const data = res.data.data;
        setHelpCenter(data);

        setCompanyName(res.data.data.company_name);
        setEmail(res.data.data.email);
        setMobileNumber(res.data.data.phone);
        setAddress(res.data.data.address);
        setMinimumOrder(res.data.data.minimum_order_value);

        setWebsite(res.data.data.website);
        setAccountHolderName(res.data.data.account_name);
        setAccountNumber(res.data.data.account_number);
        setIfscCode(res.data.data.ifsc);
        setBankName(res.data.data.bank_name);
        setCashback(res.data.data.referal_cashback);
        setReturnDays(res.data.data.return_days);
        setImage(res.data.data.logo);
      })
      .catch((error) => {
        setError("Error fetching data. Please try again later.");
        setLoading(false);
      });
  }, []);

  const handleSubmitForm = (e) => {
    e.preventDefault();

    const productobj = new FormData();
    productobj.append("company_name", companyName);
    productobj.append("email", email);
    productobj.append("phone", mobileNumber);
    productobj.append("address", address);
    productobj.append("minimum_order_value", minimum_order_value);
    productobj.append("website", website);
    productobj.append("account_name", accountHolderName);
    productobj.append("account_number", accountNumber);
    productobj.append("ifsc", ifscCode);
    productobj.append("bank_name", bankName);
    productobj.append("referal_cashback", cashback);
    productobj.append("return_days", returnDays);
    productobj.append("image", image);
    productobj.append("id", helpCenter.id);

    axios
      .post("/api/update_settings", productobj, {})
      .then((res) => {
        Toast.fire({
          icon: "success",
          title: res.data.message,
        });

        navigate("/settings");
      })
      .catch((error) => {
        Toast.fire({
          icon: "error",
          title: error.response.data.message,
        });
      });
  };

  const handleNumericInput = (e, setterFunction) => {
    const inputValue = e.target.value;

    if (/^\d*$/.test(inputValue) && inputValue.length <= 10) {
      setterFunction(inputValue);
    }
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setImage(selectedImage);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-2 p-0">
          <Sidebar />
        </div>
        <div className="col-sm-10 ">
          <div className="container-fluid">
            <Navbar />
            <div className="p-2 bg-white">
              <span>
                <Link to="/dashboard">Dashboard</Link> /{" "}
                <Link to="/settings">Account Setting</Link> / Edit
              </span>
            </div>
            <div className="bg-gre " style={{ padding: "50px" }}>
              <AccordionDetails>
                <Typography>
                  <div className="main-box mb-2 shadow-lg p-3 mb-5 bg-body-tertiary rounded-5">
                    <div className="text-box">
                      <strong className="m-3">
                        <CCardHeader>Company Details</CCardHeader>
                      </strong>

                      <div className="mb-3">
                        {helpCenter && (
                          <img
                            src={image}
                            alt="Company Logo"
                            className="logo-image"
                            style={{ height: "100px", width: "100px" }}
                          />
                        )}
                      </div>

                      <div className="input-field w-40 mb-4">
                        <input
                          type="file"
                          accept="image/png, image/jpeg, image/jpg, image/webp"
                          onChange={handleImageChange}
                        />
                      </div>

                      <TextField
                        id="outlined-basic"
                        label="Company Name"
                        variant="outlined"
                        className="input-field w-40 mb-2"
                        type="text"
                        name="companyname"
                        placeholder="Company Name"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        sx={{ color: "#FFFFFF" }}
                      />

                      <TextField
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        className="input-field w-40 mb-2"
                        name="email"
                        type="text"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{ color: "#FFFFFF" }}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Mobile Number"
                        variant="outlined"
                        className="input-field w-40 mb-2"
                        type="text"
                        name="mobileNumber"
                        placeholder="Mobile Number"
                        value={mobileNumber}
                        onChange={(e) => handleNumericInput(e, setMobileNumber)}
                        sx={{ color: "#FFFFFF" }}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Address"
                        variant="outlined"
                        className="input-field w-40 mb-2"
                        type="text"
                        name="address"
                        placeholder="Address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        sx={{ color: "#FFFFFF" }}
                      />

                      <TextField
                        id="outlined-basic"
                        label="Website"
                        variant="outlined"
                        className="input-field w-40 mb-2"
                        type="text"
                        name="website"
                        placeholder="Website"
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                        sx={{ color: "#FFFFFF" }}
                      />
                       <TextField
                        id="outlined-basic"
                        label="Minimum Order Value"
                        variant="outlined"
                        className="input-field w-40 mb-2"
                        type="text"
                        name="minimum_order_value"
                        placeholder="Minimum Order Value"
                        value={minimum_order_value}
                        onChange={(e) => setMinimumOrder(e.target.value)}
                        sx={{ color: "#FFFFFF" }}
                      />
                    </div>
                  </div>
                </Typography>
              </AccordionDetails>

              <AccordionDetails>
                <Typography>
                  <div className="main-box mb-2 shadow-lg p-3 mb-5 bg-body-tertiary rounded-5">
                    <div className="text-box">
                      <strong className="m-3">
                        <CCardHeader>Account Details</CCardHeader>
                      </strong>

                      <TextField
                        id="outlined-basic"
                        label="Account Holder Name"
                        variant="outlined"
                        className="input-field w-40 mb-2"
                        type="text"
                        name="accountholdername"
                        placeholder="Account Holder Name"
                        value={accountHolderName}
                        onChange={(e) => setAccountHolderName(e.target.value)}
                        sx={{ color: "#FFFFFF" }}
                      />

                      <TextField
                        id="outlined-basic"
                        label="Account Number"
                        variant="outlined"
                        className="input-field w-40 mb-2"
                        name="accountnumber"
                        type="text"
                        placeholder="Account Number"
                        value={accountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)}
                        sx={{ color: "#FFFFFF" }}
                      />
                      <TextField
                        id="outlined-basic"
                        label="IFSC Code"
                        variant="outlined"
                        className="input-field w-40 mb-2"
                        type="text"
                        name="ifsccode"
                        placeholder="IFSC Code"
                        value={ifscCode}
                        onChange={(e) => setIfscCode(e.target.value)}
                        sx={{ color: "#FFFFFF" }}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Bank Name"
                        variant="outlined"
                        className="input-field w-40 mb-2"
                        type="text"
                        name="bankname"
                        placeholder="Bank Name"
                        value={bankName}
                        onChange={(e) => setBankName(e.target.value)}
                        sx={{ color: "#FFFFFF" }}
                      />
                    </div>
                  </div>
                </Typography>
              </AccordionDetails>

              <AccordionDetails>
                <Typography>
                  <div className="main-box mb-2 shadow-lg p-3 mb-5 bg-body-tertiary rounded-5">
                    <div className="text-box">
                      <strong className="m-3">
                        <CCardHeader>Other Details</CCardHeader>
                      </strong>

                      <TextField
                        id="outlined-basic"
                        label="Cashback"
                        variant="outlined"
                        className="input-field w-40 mb-2"
                        type="text"
                        name="cashback"
                        placeholder="Cashback"
                        value={cashback}
                        onChange={(e) => setCashback(e.target.value)}
                        sx={{ color: "#FFFFFF" }}
                      />

                      <TextField
                        id="outlined-basic"
                        label="Return Days"
                        variant="outlined"
                        className="input-field w-40 mb-2"
                        name="returndays"
                        type="text"
                        placeholder="Return Days"
                        value={returnDays}
                        onChange={(e) => setReturnDays(e.target.value)}
                        sx={{ color: "#FFFFFF" }}
                      />
                    </div>
                  </div>
                </Typography>
              </AccordionDetails>

              <div className="row my-2">
                <div className="col-12 text-center mb-5">
                  <button
                    onClick={handleSubmitForm}
                    className="btn btn-danger mb-2"
                    style={{}}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSetting;
