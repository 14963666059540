import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { CCard, CCardHeader } from "@coreui/react";
import CustomeLoader from "../../components/CustomeLoader";

const OutOfStock = () => {
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const [batch, setBatch] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/out_of_stock_list?page=${page}&perPage=${perPage}&product_name=${search}`
      );
      const responseData = res.data.data.data;
      res.data.status == "Success" ? setBatch(responseData) : setBatch([]);

      setPerPage(res.data.data.per_page);
      setTotalRows(res.data.data.total);
    } catch (error) { }
  };

  useEffect(() => {
    fetchData();
  }, [page, perPage, search]);

  const handleKeyUp = (e) => {
    setSearch(e.target.value);
  };

  const columns = [
    {
      name: "S.No.",
      selector: (row, index) => index + 1,
      width: "70px",
    },

    {
      name: "Product",
      selector: (row) => {
        const productName = row.product_name
          ? row.product_name
          : "No Product Available";
        if (productName.length > 40) {
          return (
            <div
              className="text-capital"
              style={{ whiteSpace: "pre-line", width: "170px" }}
            >
              {productName.match(new RegExp(".{1," + 40 + "}", "g")).join("\n")}
            </div>
          );
        } else {
          return <span className="text-capital">{productName}</span>;
        }
      },
      width: "250px",
    },


    {
      name: "Manufacturer Name",
      selector: (row) => <span className="text-capital">
        {row.manufacturer_name ? row.manufacturer_name : "Not Available"}
      </span>,
      width: "250px",
    },
    {
      name: "Shop Name",
      selector: (row) => <span className="text-capital">{row.shop_name ? row.shop_name : "Not Available"}</span>,
      width: "250px",
    },
    {
      name: "Customer Name",
      selector: (row) => (
        <span className="text-capital">
          {row.customer_name ? row.customer_name : "Not Available"}
        </span>
      ),
      width: "200px",
    },

    {
      name: "Date",
      selector: (row) => new Date(row.created_at).toLocaleString(),
      width: "200px",
    },
  ];

  const searchProduct = (e) => {
    setSearch(e.target.value);
  };

  const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;
    &:hover {
      cursor: pointer;
    }
  `;

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  const handleRowsPerPageChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setPage(page);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="container-fluid">
            <CCard className=" shadow-lg p-3 mb-3 bg-body-tertiary rounded-4 ml-3">
              <CCardHeader>
                <strong>Customer request to stock products</strong>
              </CCardHeader>
              <div className="customerContainer">
                <div className="col-sm-6 mt-1">
                  <TextField
                    type="text"
                    placeholder="Search...."
                    value={filterText}
                    autoFocus
                    onChange={(e) => setFilterText(e.target.value)}
                    onKeyUp={handleKeyUp}
                  />
                  <button className="btn btn-primary m-1" onClick={handleClear}>
                    X
                  </button>
                </div>

                <div style={{ margin: "1%" }}>
                  {batch.length == 0 ? (
                    <div className="text-center fw-600 my-5 fs-18 text-red">
                      <span>No Data Available</span>
                    </div>
                  ) : (
                    <DataTable
                      columns={columns}
                      data={batch}
                      defaultSortFieldId
                      fixedHeader
                      responsive
                      pagination
                      paginationServer
                      paginationTotalRows={totalRows}
                      paginationPerPage={perPage}
                      paginationRowsPerPageOptions={[10, 20, 30, 50]}
                      onChangePage={(page) => setPage(page)}
                      progressComponent={<CustomeLoader />}
                      onChangeRowsPerPage={(newPerPage, page) =>
                        handleRowsPerPageChange(newPerPage, page)
                      }
                    />
                  )}
                </div>
              </div>
            </CCard>
          </div>
        </div>
      </div>
    </>
  );
};
export default OutOfStock;
